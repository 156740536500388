import { faDeleteLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import authStore from '../../store/AuthStore'
import Loading from '../UI/Loading/Loading'
import './Login.css'

const Login: React.FC<any> = ({ children }) => {
  const limitKeyWord: number = 4
  const isLogged = authStore((state) => state.isLogged)
  const tokenUser = authStore((state) => state.token_user)
  const authLogin = authStore((state) => state.authLogin)
  const authLogout = authStore((state) => state.authLogout)
  const [keyPass, setKeyPass] = React.useState<string>('')
  const [puces, setPuces] = React.useState<string[]>([])
  const number = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'X', '0', 'S']
  const [authError, setAuthError] = React.useState<boolean>(false)
  const [isLoading, setLoading] = React.useState<boolean>(false)

  const handleChange = (value: string) => {
    let secret = keyPass + value
    if (secret.length < limitKeyWord) {
      setKeyPass(secret)
      setPuces([...puces, secret])
    } else if (secret.length === limitKeyWord) {
      setLoading(true)
      setKeyPass(secret)
      setPuces([...puces, secret])

      axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + 'delivery/login',
        headers: {
          Authorization: 'Bearer ' + process.env.REACT_APP_TOKEN,
        },
        data: { pass: secret },
      })
        .then(function (response) {
          if (response.data.message === 'error') {
            setLoading(false)
            setAuthError(true)
          } else {
            setLoading(false)
            authLogin(true, response.data.token, response.data.key)
            handleInit()
          }
        })
        .catch(function (error: any) {
          console.log(error)
        })
    }
  }

  const handleDeleteWord = () => {
    setKeyPass(keyPass.slice(0, -1))
    setPuces(puces.slice(0, -1))
  }

  React.useEffect(() => {
    setLoading(true)
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + 'delivery/token',
      headers: {
        Authorization: 'Bearer ' + process.env.REACT_APP_TOKEN,
      },
      data: { token: tokenUser },
    })
      .then(function (response) {
        if (response.data.message === 'error') {
          authLogout()
          authLogin(false, null, null)
          setLoading(false)
        } else {
          authLogin(true)
          setLoading(false)
        }
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }, [])

  const handleInit = () => {
    setAuthError(false)
    setKeyPass('')
    setPuces([])
  }

  return (
    <>
      {!isLogged ? (
        <>
          {isLoading ? (
            <Loading text="Chargement de l'application" />
          ) : (
            <Container>
              <Row className='text-center'>
                <Col className='mb-3'>
                  <img
                    src={process.env.PUBLIC_URL + '/logo-dark.png'}
                    width={80}
                    alt={'Logo OnWine'}
                  />
                </Col>
              </Row>
              {authError ? (
                <Row className='text-center mt-4'>
                  <Col className='error'>
                    <p className='text-danger'>Identifiant incorrect</p>
                    <br />
                    <button className='btn btn-outline btn-danger' onClick={handleInit}>
                      Veuillez recommencer
                    </button>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className='text-center mt-4'>
                    <Col className='keypass'>
                      <ul className='ps-0'>
                        {puces.map((puce: string) => (
                          <li key={Math.random()}>{puce}</li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  <Row className='mx-auto mt-2' style={{ width: '80%' }}>
                    {number.map((num: any) => (
                      <>
                        {num === 'S' ? (
                          <Col
                            key={num}
                            className='touch-number g-1'
                            xs={4}
                            onClick={handleDeleteWord}
                          >
                            <span className=' d-block'>
                              <FontAwesomeIcon icon={faDeleteLeft} />
                            </span>
                          </Col>
                        ) : num === 'X' ? (
                          <Col
                            key={num}
                            className='touch-number g-1'
                            xs={4}
                            onClick={handleInit}
                          >
                            <span className=' d-block'>
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </Col>
                        ) : (
                          <Col
                            key={num}
                            className='touch-number g-1'
                            xs={4}
                            onClick={() => handleChange(num)}
                          >
                            <span className=' d-block'>{num}</span>
                          </Col>
                        )}
                      </>
                    ))}
                  </Row>
                  <Row>
                    <Col className='text-center mt-3'>
                      <small>Version {process.env.REACT_APP_VERSION}</small>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          )}
        </>
      ) : isLoading ? (
        <Loading text="Chargement de l'application" />
      ) : (
        children
      )}
    </>
  )
}

export default Login
