import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxesAlt, faCheckCircle, faTruckFast } from '@fortawesome/free-solid-svg-icons'
import { Nav } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import './Navigation.css'

const Navigation: React.FC<any> = () => {
  const { pathname } = useLocation()

  return (
    <Nav fill variant='pills'>
      <Nav.Item className={`${pathname === '/' ? 'active' : null}`}>
        <Link to={'/'}>
          <FontAwesomeIcon icon={faBoxesAlt} />
          Nouvelles <br />
          commandes
        </Link>
      </Nav.Item>
      <Nav.Item className={`${pathname === '/commandes-deposees' ? 'active' : null}`}>
        <Link to={'/commandes-deposees'}>
          <FontAwesomeIcon icon={faCheckCircle} />
          Commandes
          <br />
          déposées
        </Link>
      </Nav.Item>
      <Nav.Item className={`${pathname === '/commandes-a-recuperer' ? 'active' : null}`}>
        <Link to={'/commandes-a-recuperer'}>
          <FontAwesomeIcon icon={faTruckFast} />
          Commandes
          <br /> à récupérer
        </Link>
      </Nav.Item>
    </Nav>
  )
}

export default Navigation
