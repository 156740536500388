import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Order from './components/Order/Order'
import NewOrders from './pages/NewOrders'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import Header from './components/Layout/Header/Header'
import Navigation from './components/Layout/Navigation/Navigation'
import OrdersInLocker from './pages/OrdersInLocker'
import OverTimeOrders from './pages/OverTimeOrders'
import Auth from './components/Auth/Login'
import AuthTimer from './components/Auth/AuthTimer'
import QrCode from './pages/QrCode'
import NotFound from './pages/NotFound'

ReactDOM.render(
  <React.StrictMode>
    <Auth>
      <AuthTimer>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path='/' element={<NewOrders />} />
            <Route path='/qrcode' element={<QrCode />} />
            <Route path='/commande/:orderId' element={<Order />} />
            <Route path='/commandes-deposees' element={<OrdersInLocker />} />
            <Route path='/commandes-a-recuperer' element={<OverTimeOrders />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Navigation />
        </BrowserRouter>
      </AuthTimer>
    </Auth>
  </React.StrictMode>,
  document.getElementById('root')
)
serviceWorkerRegistration.register()
