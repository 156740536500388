import React from 'react'
import { Card, Container, Row, Col, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './Order.css'

interface Order {
  id_order: number
  firstname: string
  lastname: string
  nb_parcel: number
}

type OrderProps = {
  title: string
  list: Order[]
}

const OrderList: React.FC<OrderProps> = ({ title, list }) => {
  const [launchTransition, setLaunchTransition] = React.useState<string>('')
  const history = useNavigate()

  const onClickNavigation = (id: number) => {
    setLaunchTransition('transition')
    setTimeout(() => {
      history('/commande/' + id)
    }, 300)
  }

  return (
    <Container className={'pt-3 ' + launchTransition}>
      <h6 className='title'>{title}</h6>
      {list.map((order: Order) => (
        <Card
          key={Math.random()}
          className='mb-2'
          onClick={() => onClickNavigation(order.id_order)}
        >
          <Card.Body>
            <Row>
              <Col xs={2} className='text-center'>
                <span className='parcel'>
                  {order.nb_parcel}
                  <br />
                  <Image src={process.env.PUBLIC_URL + '/box.png'} alt='image' width={30} />
                </span>
              </Col>
              <Col>
                <span className='bl d-block mt-2'>Commande : #{order.id_order}</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </Container>
  )
}

export default OrderList
