import React from 'react'
import { Spinner } from 'react-bootstrap'

const Error: React.FC<any> = () => {
  return (
    <div className='loading text-center'>
      <Spinner animation='border' variant='danger' />
      <p className='mt-3'>
        La liaison au serveur a été interrompue. <br />
        Veuillez réessayer dans quelques instants.
      </p>
    </div>
  )
}
export default Error
