import React from 'react'
import { Spinner } from 'react-bootstrap'
import './Loading.css'

const Loading: React.FC<any> = ({ text }) => {
  return (
    <div className='loading text-center'>
      <Spinner animation='border' variant='secondary' />
      <br />
      <small className='mt-3'>{text}</small>
    </div>
  )
}

export default Loading
