import axios from 'axios'
import React from 'react'
import { Container, Alert, Carousel, Card, Row, Col } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import { useParams, useNavigate } from 'react-router-dom'
import Loading from '../UI/Loading/Loading'
import './Order.css'

const Order: React.FC<any> = () => {
  let params = useParams()
  const history = useNavigate()
  const [order, setOrder] = React.useState<any[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [launchTransition, setLaunchTransition] = React.useState<string>('')

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + 'order/' + params.orderId, {
        headers: {
          Authorization: 'Bearer ' + process.env.REACT_APP_TOKEN,
        },
      })
      .then((response) => {
        setOrder(response.data)
        setIsLoading(false)
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const goBack = () => {
    setLaunchTransition('transition')
    setTimeout(() => {
      history('/')
    }, 300)
  }

  const getZone = (param: string) => {
    if (param === 'MT') {
      return 'normale'
    } else {
      return 'froide'
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading text='Chargement de la commande' />
      ) : (
        <>
          <div className='back-home'>
            <div className='text-white' onClick={goBack}>
              <img
                src={process.env.PUBLIC_URL + '/circled-left.png'}
                alt='Go Back'
                width={38}
              />
            </div>
          </div>
          <Container className={'pt-3 ' + launchTransition}>
            <h6 className='title text-center'>Commande : #{order[0].id_order}</h6>
            <Alert variant='danger' className='mb-4 text-center'>
              <small>
                <p className='mb-2 fw-bold fs-6 d-block text-center'>Important !</p>
                <hr />
                Une fois le colis déposé, veuillez vous assurer de confirmer la zone de
                température <strong>sur l'écran de la Drive Box</strong>
              </small>
            </Alert>
            <Alert variant='info' className='mb-4'>
              <small>
                <p className='mb-2 fw-bold fs-6 d-block text-center'>
                  {order.length} {order.length > 1 ? 'paniers' : 'panier'} pour cette commande
                </p>
                {order[0].slot_id && (
                  <>
                    <hr />
                    <ul className='mb-0'>
                      {order.map((panier: any) => (
                        <li>
                          Barcode : {panier.barcode} - Slot ID : {panier.slot_id}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </small>
            </Alert>

            {order.length > 1 ? (
              <>
                <Carousel interval={900000} className='text-center'>
                  {order.map((item: any, index: number) => (
                    <Carousel.Item key={Math.random()}>
                      <Card>
                        <Card.Header>
                          <b>
                            Panier {++index} - Zone {getZone(item.temperature_zone)}
                          </b>
                        </Card.Header>
                        <Card.Body>
                          <small className='text-danger d-block mb-3'>
                            Veuillez respecter le sens de l'orientation du QR-Code
                          </small>
                          <hr />
                          <div className='mb-3'>
                            <img
                              src='/arrow-top.png'
                              alt='icon'
                              width={120}
                              className='img-fluid'
                            />
                          </div>
                          <QRCode value={item.barcode} />
                          <div className='mt-3'>
                            <img
                              src='/arrow-bottom.png'
                              alt='icon'
                              width={60}
                              className='img-fluid'
                            />
                          </div>
                          <hr />
                          <p className=''>
                            <small>Code de secours :</small>
                            <br />
                            <small className='bg-info py-1 px-2'>
                              <strong>{item.barcode}</strong>
                            </small>
                            <br />
                            <small className='text-muted'>
                              A saisir manuellement sur la Drive Box
                            </small>
                          </p>
                        </Card.Body>
                      </Card>
                    </Carousel.Item>
                  ))}
                </Carousel>
                <div className='text-center mt-3 mb-3'>
                  <img src='/swipe.png' alt='Info Swipe' width={60} />
                  <br />
                  <br />
                  <small className='text-muted'>
                    Faites défiler les QRCodes
                    <br /> de gauche à droite
                  </small>
                </div>
              </>
            ) : (
              <>
                <Card className='text-center'>
                  <Card.Header>
                    <b>1 panier - Zone {getZone(order[0].temperature_zone)}</b>
                  </Card.Header>
                  <Card.Body>
                    <small className='text-danger d-block mb-3'>
                      Veuillez respecter le sens de l'orientation du QR-Code
                    </small>
                    <hr />
                    <div className='mb-3'>
                      <img src='/arrow-top.png' alt='icon' width={120} className='img-fluid' />
                    </div>
                    <QRCode value={order[0].barcode} />
                    <div className='mt-3'>
                      <img
                        src='/arrow-bottom.png'
                        alt='icon'
                        width={60}
                        className='img-fluid'
                      />
                    </div>
                    <hr />
                    <p className=''>
                      <small>Code de secours :</small>
                      <br />
                      <small className='bg-info py-1 px-2'>
                        <strong>{order[0].barcode}</strong>
                      </small>
                      <br />
                      <small className='text-muted'>
                        A saisir manuellement sur la Drive Box
                      </small>
                    </p>
                  </Card.Body>
                </Card>
              </>
            )}
          </Container>
        </>
      )}
    </>
  )
}

export default Order
