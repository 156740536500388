import create from 'zustand'
import { persist } from 'zustand/middleware'

const authStore = create<any>(
  persist(
    (set) => ({
      // initial state
      isLogged: false,
      token_user: null,
      _q: null,
      // methods for manipulating state
      authLogin: (status_logged: boolean, token: string, k: string) =>
        set((state: any) => ({ isLogged: status_logged, token_user: token, _q: k })),
      authLogout: () => set((state: any) => ({ isLogged: false, token_user: null, _q: null })),
    }),
    {
      name: 'auth-storage', // unique name
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
)

export default authStore
