import create from 'zustand'
import { Order } from '../model/Order'

interface OrderState {
  orders: Order[]
}

const useStore = create<OrderState>((set) => ({
  // initial state
  orders: [],
  // methods for manipulating state
  saveOrders: (list: any) => {
    set((state) => ({
      orders: [...state.orders, list],
    }))
  },
}))

export default useStore
