import React from 'react'
import { Container, Alert } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import EmptyOrder from '../components/Order/EmptyOrder'
import authStore from '../store/AuthStore'

const QrCode: React.FC<any> = () => {
  const qrCode = authStore((state: any) => state._q)

  return (
    <Container className={'pt-4 text-center mx-auto'}>
      {qrCode !== '' ? (
        <>
          <Alert variant='info' className='text-center mb-5 p-2'>
            Utilisez ce QRCode pour vous connecter au Drive Box
          </Alert>
          <QRCode value={qrCode} />
        </>
      ) : (
        <EmptyOrder text={'Veuillez cliquer sur la rubrique "Nouvelles commandes"'} />
      )}
    </Container>
  )
}
export default QrCode
