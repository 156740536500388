import React from 'react'
import { Image } from 'react-bootstrap'

const EmptyOrder: React.FC<any> = ({ text }) => {
  return (
    <div className='text-center mt-5'>
      <Image src={process.env.PUBLIC_URL + '/truck.png'} alt='Image' className='empty-order' />
      <h6 className='title'>{text}</h6>
    </div>
  )
}

export default EmptyOrder
