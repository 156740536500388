import axios from 'axios'
import React from 'react'
import EmptyOrder from '../components/Order/EmptyOrder'
import OrderList from '../components/Order/OrderList'
import Error from '../components/UI/Error/Error'
import Loading from '../components/UI/Loading/Loading'
import useStore from '../store/OrderStore'

const OverTimeOrders: React.FC<any> = () => {
  const [orderList, setOrderList] = React.useState<any[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isError, setIsError] = React.useState<boolean>(false)
  const orders = useStore((state: any) => state.orders)

  React.useEffect(() => {
    if (orders.length === 0) {
      axios
        .get(process.env.REACT_APP_API_URL + 'orders/overtime', {
          headers: {
            Authorization: 'Bearer ' + process.env.REACT_APP_TOKEN,
          },
        })
        .then((response) => {
          setIsLoading(false)
          setOrderList(response.data)
        })
        .catch((error) => {
          setIsError(error)
        })
    } else {
      setIsLoading(false)
      setOrderList(orders[0])
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <Loading text='Chargement des commandes...' />
      ) : isError ? (
        <Error />
      ) : orderList.length === 0 ? (
        <EmptyOrder text={'Aucunes commandes à récupérer'} />
      ) : (
        <OrderList title={'Commandes à récupérer'} list={orderList} />
      )}
    </>
  )
}
export default OverTimeOrders
