import React from 'react'
import authStore from '../../store/AuthStore'

const AuthTimer: React.FC<any> = ({ children }) => {
  const authLogin = authStore((state) => state.authLogin)
  const authLogout = authStore((state) => state.authLogout)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      authLogout()
      authLogin(false, null)
    }, 600000)
    return () => clearTimeout(timer)
  }, [])

  return <>{children}</>
}

export default AuthTimer
