import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import './Header.css'
import logo from '../../../logo-white.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import authStore from '../../../store/AuthStore'
import { Link } from 'react-router-dom'

const Header: React.FC<any> = () => {
  const authLogout = authStore((state) => state.authLogout)
  const qrCode = authStore((state: any) => state._q)

  return (
    <Navbar>
      <Container>
        <Navbar.Brand className='mx-auto'>
          {qrCode !== '' && (
            <div className='qr-code' style={{ marginLeft: '40px' }}>
              <Link to='/qrcode'>
                <FontAwesomeIcon icon={faBarcode} className='text-white' />
              </Link>
            </div>
          )}
          <img src={logo} alt='OnWine' width={50} />
          <div className='logout'>
            <FontAwesomeIcon icon={faPowerOff} onClick={authLogout} className='text-white' />
          </div>
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}

export default Header
